<template>
  <div class="home">
    <div style="
        background: #fff;
        padding: 30px 0;
        box-shadow: 0 1px 4px 0 rgb(220, 220, 220, 0.6);
      ">
      <div class="full-home-banners-wrapper">
        <div style="width: 796px;">
          <el-carousel height="243px">
            <el-carousel-item v-for="(item, i) in $store.state.banner_module" :key="i">
              <el-image @click="handleJump(item.jump_link)" :src="item.image"
                style="width: 100%; height: 100%; cursor: pointer" fit="cover"></el-image>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div style="width: 398px">
          <div v-for="(item, i) in $store.state.advert_module" :key="i">
            <img @click="handleJump(item.jump_link)" :src="item.image" alt="" style="width: 100%; height: 100%" />
          </div>
        </div>
      </div>
    </div>
    <div class="main-card">
      <!-- <div class="home-category">
        <div class="title">
          <span>分类</span>
          <span @click="toAll1" style="font-size: 14px; cursor: pointer"
            >查看全部
            <i class="el-icon-arrow-right"></i>
          </span>
        </div>
        <div class="content" v-if="categoryWidth != 2400">
          <div
            class="gongd"
            :style="{
              width: categoryWidth + 'px',
              transform: 'translateX(' + mobDist + 'px)',
              transition: '0.6s',
            }"
          >
            <div
              class="item"
              v-for="item in $store.state.homeMainData"
              :key="item.id"
              @click="toCategory(item.id)"
            >
              <img
                :src="item.image"
                style="width: 100px; height: 100px"
                alt=""
              />
              <div style="padding: 0 10px; min-height: 40px">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="left-btn" @click="dhShow = false" v-if="categoryPage != 1">
          <i class="el-icon-arrow-left"></i>
        </div>
        <div class="right-btn" @click="handleTheRight" v-else>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div> -->

      <div class="home-category">
        <div class="title">
          <span>分类</span>
          <span @click="toAll1" style="font-size: 14px; cursor: pointer">查看全部
            <i class="el-icon-arrow-right"></i>
          </span>
        </div>
        <div class="content">
          <div class="gongd" :class="dhShow ? 'donghua1' : 'donghua2'">
            <div class="item" v-for="item in $store.state.homeMainData" :key="item.id" @click="toCategory(item.id)">
              <img :src="item.image" style="width: 100px; height: 100px" alt="" />
              <div style="padding: 0 10px; min-height: 40px">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="left-btn" @click="dhShow = false" v-if="dhShow">
          <i class="el-icon-arrow-left"></i>
        </div>
        <div class="right-btn" @click="dhShow = true" v-else>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>

    <div class="main-card" v-if="homeInfoData.hot_goods_module &&
      homeInfoData.hot_goods_module.length != 0
      ">
      <div class="hot-goods" v-if="homeInfoData.limit_goods && homeInfoData.limit_goods.length != 0">
        <div class="title">
          <span>限时选购</span>
          <span style="font-size: 14px; cursor: pointer" @click="toAll2">查看全部
            <i class="el-icon-arrow-right"></i>
          </span>
        </div>
        <div class="content">
          <div class="gongd" :class="dhShowa ? 'donghua1' : 'donghua2'">
            <div class="item" @click="toGoodsInfo(item.goods_id)" v-for="item in homeInfoData.limit_goods"
              :key="item.id">
              <!-- <img :src="topImg" alt="" class="top-img" /> -->
              <div style="
                  width: 46px;
                  height: 42px;
                  background-color: rgba(255, 212, 36, 0.9);
                  position: absolute;
                  right: 0;
                  z-index: 1;
                  font-size: 14px;
                  text-align: center;
                  padding: 4px;
                ">
                <div style="color: #ee4d2d">
                  {{ (parseFloat(item.discount_ratio) / 10).toFixed(1) }}
                </div>
                <div style="color: #fff">折扣</div>
                <div style="
                    width: 0;
                    height: 0;
                    left: 0;
                    bottom: -4px;
                    position: absolute;
                    border-color: transparent rgba(255, 212, 36, 0.9);
                    border-style: solid;
                    border-width: 0 23px 4px;
                  "></div>
              </div>
              <el-image style="
                  width: 180px;
                  height: 180px;
                  text-align: center;
                  line-height: 200px;
                " :src="item.goods_image" fit="fit">
                <svg slot="error" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 61" fill="#e5e4e4"
                  style="width: 50px; height: 50px">
                  <path
                    d="M51.2 16.9H38.7C38.7 11.6 36 .6 27 .5 17.4.4 15.2 12.4 15.2 16.9H2.8c-3.4 0-2.7 3.4-2.7 3.4l2.4 33s-.1 7.3 6.3 7.5h36.5c6.2-.4 6.3-7.5 6.3-7.5l2.4-33c0-.1.5-3.5-2.8-3.4zM27.1 4.2c7.1.2 7.9 11.7 7.7 12.6H19.1c-.1-.9.4-12.4 8-12.6zm9.1 44.6c-1 1.7-2.7 3-5 3.7-1.2.4-2.4.5-3.6.5-3.2 0-6.5-1.1-9.3-3.3-.8-.6-1-1.5-.5-2.3.2-.4.7-.7 1.2-.8.4-.1.9 0 1.2.3 3.2 2.4 8.3 4 11.9 1.6 1.4-.9 2.1-2.7 1.6-4.3-.5-1.6-2.2-2.7-3.5-3.4-1-.6-2.1-1-3.3-1.4-.9-.3-1.9-.7-2.9-1.2-2.4-1.2-4-2.6-4.8-4.2-1.2-2.3-.6-5.4 1.4-7.5 3.6-3.8 10-3.2 14-.4.9.6.9 1.7.4 2.5s-1.4.9-2.2.4c-2-1.4-4.4-2-6.4-1.7-2 .3-4.7 2-4.4 4.6.2 1.5 2 2.6 3.3 3.3.8.4 1.5.7 2.3.9 4.3 1.3 7.2 3.3 8.6 5.7 1.2 2.1 1.2 4.9 0 7z" />
                </svg></el-image>
              <div style="text-align: center; color: #ee4d2d; font-size: 14px">
                RM
                <span style="font-size: 18px">{{ item.discount_price }}</span>
              </div>
              <div style="
                  background: #ffbda6;
                  width: 90%;
                  margin: 0 auto;
                  height: 16px;
                  border-radius: 10px;
                  color: #fff;
                  font-size: 12px;
                  text-align: center;
                  position: relative;
                ">
                已售{{ item.sales }}
                <div style="
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 10%;
                    height: 16px;
                    border-radius: 10px 0 0 10px;
                    background: #ed2615;
                    z-index: 1;
                  "></div>
              </div>
            </div>
          </div>
        </div>
        <div class="left-btn" @click="dhShowa = false" v-if="dhShowa">
          <i class="el-icon-arrow-left"></i>
        </div>
        <div class="right-btn" @click="dhShowa = true" v-if="!dhShowa &&
          homeInfoData.limit_goods &&
          homeInfoData.limit_goods.length > 6
          ">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>
    <div class="main-card" v-if="homeInfoData.hot_goods_module &&
      homeInfoData.hot_goods_module.length != 0
      ">
      <div class="hot-goods">
        <div class="title">
          <span>热门商品</span>
          <span style="font-size: 14px; cursor: pointer" @click="toAll">查看全部
            <i class="el-icon-arrow-right"></i>
          </span>
        </div>
        <div class="content">
          <div class="gongd" :class="dhShows ? 'donghua1' : 'donghua2'">
            <div class="item" @click="toGoodsInfo(item.id)" v-for="item in homeInfoData.hot_goods_module"
              :key="item.id">
              <img :src="topImg" alt="" class="top-img" />
              <div class="xiaol">月销量{{ item.sales }}</div>
              <el-image style="
                  width: 180px;
                  height: 180px;
                  text-align: center;
                  line-height: 200px;
                " :src="item.image" fit="fit">
                <svg slot="error" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 61" fill="#e5e4e4"
                  style="width: 50px; height: 50px">
                  <path
                    d="M51.2 16.9H38.7C38.7 11.6 36 .6 27 .5 17.4.4 15.2 12.4 15.2 16.9H2.8c-3.4 0-2.7 3.4-2.7 3.4l2.4 33s-.1 7.3 6.3 7.5h36.5c6.2-.4 6.3-7.5 6.3-7.5l2.4-33c0-.1.5-3.5-2.8-3.4zM27.1 4.2c7.1.2 7.9 11.7 7.7 12.6H19.1c-.1-.9.4-12.4 8-12.6zm9.1 44.6c-1 1.7-2.7 3-5 3.7-1.2.4-2.4.5-3.6.5-3.2 0-6.5-1.1-9.3-3.3-.8-.6-1-1.5-.5-2.3.2-.4.7-.7 1.2-.8.4-.1.9 0 1.2.3 3.2 2.4 8.3 4 11.9 1.6 1.4-.9 2.1-2.7 1.6-4.3-.5-1.6-2.2-2.7-3.5-3.4-1-.6-2.1-1-3.3-1.4-.9-.3-1.9-.7-2.9-1.2-2.4-1.2-4-2.6-4.8-4.2-1.2-2.3-.6-5.4 1.4-7.5 3.6-3.8 10-3.2 14-.4.9.6.9 1.7.4 2.5s-1.4.9-2.2.4c-2-1.4-4.4-2-6.4-1.7-2 .3-4.7 2-4.4 4.6.2 1.5 2 2.6 3.3 3.3.8.4 1.5.7 2.3.9 4.3 1.3 7.2 3.3 8.6 5.7 1.2 2.1 1.2 4.9 0 7z" />
                </svg></el-image>
              <div style="
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  padding: 0 10px;
                ">
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
        <div class="left-btn" @click="dhShows = false" v-if="dhShows">
          <i class="el-icon-arrow-left" v-if="homeInfoData.hot_goods_module &&
            homeInfoData.hot_goods_module.length != 0
            "></i>
        </div>
        <div class="right-btn" @click="dhShows = true" v-if="!dhShows &&
          homeInfoData.hot_goods_module &&
          homeInfoData.hot_goods_module.length > 6
          ">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>

    <div class="main-card" v-if="homeInfoData.new_goods_module &&
      homeInfoData.new_goods_module.length != 0
      ">
      <div class="day-found">
        <div class="title">
          <div style="
              width: 172px;
              height: 60px;
              border-bottom: 4px solid #ee4d2d;
              text-align: center;
            ">
            每日新发现
          </div>
        </div>
        <div class="content">
          <div v-for="item in homeInfoData.new_goods_module" :key="item.id">
            <HomeGoods :data="item" :type="true"></HomeGoods>
          </div>
        </div>
        <div class="found-btn" @click="toPush" v-if="homeInfoData.new_goods_module &&
          homeInfoData.new_goods_module.length > 60
          ">
          查看更多
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { allMainCategory } from "@/api/common.js";

  import { hotGoodsCategory, homeInfo } from "@/api/home.js";
  import HomeGoods from "@/components/goods/HomeGoods";
  export default {
    components: {
      HomeGoods,
    },
    data() {
      return {
        swpImgList: [
          require("@/assets/img/home/swp1.jpg"),
          require("@/assets/img/home/swp2.jpg"),
          require("@/assets/img/home/swp3.jpg"),
        ],
        rightImg: [
          require("@/assets/img/home/home-r1.jpg"),
          require("@/assets/img/home/home-r2.jpg"),
        ],
        topImg: require("@/assets/img/home/top.png"),
        dhShow: false,
        dhShows: false,
        dhShowa: false,
        homeInfoData: [],
        categoryPage: 1,
        categoryWidth: 2400,
        mobDist: 0,
      };
    },
    created() {
      this.$store.state.searchValue = "";
      // localStorage.SHOPEE_BUY_TOKEN = "82664f1f-0311-47b2-a823-b2633e657530";
      this.getAllMainCategory();
      // this.allFootCategory();
      // this.getHotGoodsCategory();
      this.getHomeInfo();
      // window.open('https://www.baidu.com');
      // window.opener=null;window.open('','_self');window.close();
    },
    methods: {
      getAllMainCategory() {
        allMainCategory().then((res) => {
          if (res.code == 1) {
            // res.data.pop();
            this.$store.state.homeMainData = res.data;
            let a = res.data;
            // a = a.concat(res.data);
            this.$store.state.homeMainData = a;
            let changdu = "";
            changdu = Math.ceil(this.$store.state.homeMainData.length / 2);
            this.categoryWidth = changdu * 120;
          }
        });
      },
      getHotGoodsCategory() {
        hotGoodsCategory().then((res) => {
          if (res.code == 1) {
            this.hotGoodsCategoryData = res.data;
          }
        });
      },
      getHomeInfo() {
        homeInfo().then((res) => {
          if (res.code == 1) {
            this.homeInfoData = res.data;
            this.$store.state.banner_module = res.data.banner_module;
            this.$store.state.advert_module = res.data.advert_module;
            this.$store.state.search_module = res.data.search_module;
          }
        });
      },
      toAll() {
        this.$router.push("hotGoods");
      },
      toAll1() {
        this.$router.push("allCategory");
      },
      toAll2() {
        this.$router.push("limitGoods");
      },
      toCategory(id) {
        sessionStorage.CATEGORYID = id;
        this.$router.push("goodsSearchs");
        window.scrollTo(0, 0);
      },
      toGoodsInfo(id) {
        this.$router.push({ path: "goodsInfo", query: { goodsId: id } });
      },
      toPush() {
        this.$router.push("allGoods");
      },
      handleJump(url) {
        if (url && url != "#") {
          window.open(url, "_blank");
        }
      },
      handleTheRight() {
        // 第一次滑动
        if (this.categoryPage == 1) {
          if (this.categoryWidth - 1200 > 1200) {
            this.mobDist = -1200;
            this.categoryPage += 1;
          } else {
            this.mobDist = -(this.categoryWidth - 1200);
          }
        }
      },
    },
  };
</script>
<style lang="less" scoped>
  .home {
    background: #f5f5f5;
    padding-bottom: 30px;

    .full-home-banners-wrapper {
      display: flex;
      justify-content: space-between;
      width: 1200px;
      margin: 0 auto;
    }

    .el-carousel__item h3 {
      color: #475669;
      font-size: 14px;
      opacity: 0.75;
      line-height: 150px;
      margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
      background-color: #d3dce6;
    }

    .home-category {
      background: #fff;
      margin-top: 20px;
      position: relative;

      .title {
        line-height: 60px;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.54);
        font-weight: 500;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .content {
        width: 1200px;
        overflow: hidden;
        position: relative;

        .gongd {
          width: 1440px;
          display: flex;
          flex-wrap: wrap;

          .item {
            width: 120px;
            height: 150px;
            // background: red;
            // border: 1px solid;
            // display: inline-block;
            cursor: pointer;
            text-align: center;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.8);
            border-right: 1px solid rgba(0, 0, 0, 0.05);
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
          }
        }

        .item:hover {
          border-right: 1px solid rgba(0, 0, 0, 0.1);
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          border-left: 1px solid rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 12px 0 rgba(220, 220, 220, 0.6);
          z-index: 1;
        }

        .donghua1 {
          transform: translateX(-240px);
          transition: 0.4s;
        }

        .donghua2 {
          transform: translateX(0px);
          transition: 0.4s;
        }
      }

      .left-btn {
        position: absolute;
        left: -12px;
        top: 198px;
        // right: 1186px;
        background: #fff;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        margin: auto;
        box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12);
        color: #000;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
      }

      .content:hover+.left-btn {
        transform: scale(2, 2);
      }

      .left-btn:hover {
        // font-size: 20px;
        transform: scale(2, 2);
        cursor: pointer;
      }

      .right-btn {
        position: absolute;
        // left: 340px;
        top: 198px;
        right: -12px;
        background: #fff;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        font-size: 10px;
        margin: auto;
        box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12);
        color: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
      }

      .content:hover+.right-btn {
        transform: scale(2, 2);
      }

      .right-btn:hover {
        // font-size: 20px;
        transform: scale(2, 2);
        cursor: pointer;
      }
    }

    .hot-goods {
      background: #fff;
      margin-top: 20px;
      position: relative;

      .title {
        line-height: 60px;
        font-size: 16px;
        color: #ee4d2d;
        font-weight: 500;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .content {
        width: 1200px;
        overflow: hidden;

        .gongd {
          width: 2400px;
          display: flex;

          .item {
            cursor: pointer;
            width: 200px;
            height: 265px;
            // background: red;
            padding: 20px 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            font-size: 18px;
            color: #555;
            position: relative;

            .top-img {
              width: 32px;
              height: 40px;
              position: absolute;
              left: 10px;
              top: 20px;
              z-index: 1;
            }

            .xiaol {
              width: 180px;
              background-color: rgba(0, 0, 0, 0.26);
              color: #fff;
              position: absolute;
              top: 176px;
              z-index: 1;
              font-size: 14px;
              line-height: 25px;
              height: 25px;
              text-align: center;
            }
          }
        }

        .donghua1 {
          transform: translateX(-1200px);
          transition: 0.6s;
        }

        .donghua2 {
          transform: translateX(0px);
          transition: 0.6s;
        }
      }

      .left-btn {
        position: absolute;
        left: -12px;
        top: 198px;
        // right: 1186px;
        background: #fff;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        margin: auto;
        box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12);
        color: #000;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .content:hover+.left-btn {
        transform: scale(2, 2);
      }

      .left-btn:hover {
        // font-size: 20px;
        transform: scale(2, 2);
        cursor: pointer;
      }

      .right-btn {
        position: absolute;
        // left: 340px;
        top: 198px;
        right: -12px;
        background: #fff;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        font-size: 10px;
        margin: auto;
        box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12);
        color: #000;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .content:hover+.right-btn {
        transform: scale(2, 2);
      }

      .right-btn:hover {
        // font-size: 20px;
        transform: scale(2, 2);
        cursor: pointer;
      }
    }

    .day-found {
      margin-top: 20px;
      position: relative;

      .title {
        line-height: 60px;
        font-size: 16px;
        color: #ee4d2d;
        font-weight: 500;
        background: #fff;
        // border-bottom: 1px solid rgba(0, 0, 0, 0.05);

        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .content {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
      }

      .found-btn {
        width: 390px;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        text-align: center;
        color: #555;
        margin: 0 auto;
        background: #fff;
        // border: 1px solid #eee;
        border: 1px solid rgba(0, 0, 0, 0.09);
        box-shadow: 0 1px 1px 0 rgb(0 0 0 / 3%);
        margin-top: 30px;
      }

      .found-btn:hover {
        background: rgba(0, 0, 0, 0.02);
        cursor: pointer;
      }
    }
  }
</style>